import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../modules/common/components/section-header';

import '../generic-page.scss';
import '../partners.scss';
import { Row, Col, Button } from 'react-bootstrap';
import { YoutubeEmbed } from '../../modules/common/components/youtube-embed';
import { faTwitch, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '../../modules/common/components/box';

const HSRWatchLivePage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page partners-page'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>Watch our partners</li>
      </ul>
      <div className="top-partner-intro hsr">
        <Box>
          <Row>
            <Col>
              <div className="inner">
                <h1>Watch our Star Rail Content Creator partners!</h1>
                <p>
                  Are you looking for good videos or streams featuring Honkai:
                  Star Rail? Check out our partners!
                </p>
              </div>
            </Col>
            <Col className="with-image">
              <StaticImage
                src="../../images/starrail/partner_img_hsr.webp"
                alt="Partner"
              />
            </Col>
          </Row>
        </Box>
      </div>
      <div className="page-content">
        <SectionHeader title="Our Partners" />
        <Row xs={1} xxl={2} className="partner-row">
          <Col>
            <Box>
              <div className="top">
                <div className="partner-image">
                  <StaticImage
                    src="../../images/partner_volkin_a.png"
                    alt="Volkin"
                  />
                </div>
                <h2>Volkin</h2>
              </div>
              <div className="middle">
                <h6>About Volkin</h6>
                <p>
                  Volkin is the best AFK Arena YouTuber who also dabbles into
                  other idle games like Eversoul - those are his favorite.
                  Still, Honkai: Star Rail brings him a new challenge that he
                  enjoys as much as idle games!
                </p>
                <h6>Check Volkin's content</h6>
                <div className="links">
                  <Button
                    variant="primary"
                    href="https://www.youtube.com/@VolkinYT/videos"
                    className="link-youtube"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faYoutube} width="18" /> YouTube
                  </Button>
                  <Button
                    variant="primary"
                    href="https://www.twitch.tv/volkingames"
                    className="link-twitch"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faTwitch} width="18" /> Twitch
                  </Button>
                </div>
                <h6>Featured videos</h6>
                <p>Check Volkin's featured videos below!</p>
              </div>
            </Box>
          </Col>
          <Col>
            <Box>
              <div className="top red">
                <div className="partner-image">
                  <StaticImage
                    src="../../images/partner_tim.png"
                    alt="TimaeuSS"
                  />
                </div>
                <h2>TimaeuSS</h2>
              </div>
              <div className="middle">
                <h6>About TimaeuSS</h6>
                <p>
                  TimaeuSS is a Dr/Papa/VTuber that can be found trying out new
                  mobile games on release. He has since settled on making NIKKE
                  and Star Rail his main games as a Modernia + Kafka Simp!
                </p>
                <h6>Check TimaeuSS content</h6>
                <div className="links">
                  <Button
                    variant="primary"
                    href="https://www.youtube.com/@TimaeuSS/featured"
                    className="link-youtube"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faYoutube} width="18" /> YouTube
                  </Button>
                  <Button
                    variant="primary"
                    href="https://www.twitch.tv/timaeuss"
                    className="link-twitch"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faTwitch} width="18" /> Twitch
                  </Button>
                </div>
                <h6>Featured videos</h6>
                <p>Check TimaeuSS featured videos below!</p>
              </div>
            </Box>
          </Col>
          <Col>
            <Box>
              <div className="top purple">
                <div className="partner-image">
                  <StaticImage
                    src="../../images/partner_pokke.png"
                    alt="MrPokke"
                  />
                </div>
                <h2>MrPokke</h2>
              </div>
              <div className="middle">
                <h6>About MrPokke</h6>
                <p>
                  MrPokke is a content creator who bridges the gap between the
                  East and the West by translating the CN guides for Honkai:
                  Star Rail.
                </p>
                <h6>Check MrPokke's content</h6>
                <div className="links">
                  <Button
                    variant="primary"
                    href="https://www.youtube.com/@mrpokkee"
                    className="link-youtube"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faYoutube} width="18" /> YouTube
                  </Button>
                  <Button
                    variant="primary"
                    href="https://www.twitch.tv/mrpokke"
                    className="link-twitch"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faTwitch} width="18" /> Twitch
                  </Button>
                </div>
                <h6>Featured videos</h6>
                <p>Check MrPokke's featured videos below!</p>
              </div>
            </Box>
          </Col>
          <Col>
            <Box>
              <div className="top">
                <div className="partner-image">
                  <StaticImage
                    src="../../images/partner_grimro.png"
                    alt="Grimro"
                  />
                </div>
                <h2>Grimro</h2>
              </div>
              <div className="middle">
                <h6>About Grimro</h6>
                <p>
                  Grimro is a Path of Exile content creator and theorycrafter
                  who decided to pick up Honkai: Star Rail as his secondary
                  game. The master of spreadsheets who listens to the numbers
                  and bends them to his will.
                </p>
                <h6>Check Grimro's content</h6>
                <div className="links">
                  <Button
                    variant="primary"
                    href="https://www.youtube.com/@GrimroGacha/videos"
                    className="link-youtube"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faYoutube} width="18" /> YouTube
                  </Button>
                </div>
                <h6>Featured videos</h6>
                <p>Check Grimro's featured videos below!</p>
              </div>
            </Box>
          </Col>
        </Row>
        <SectionHeader title="Volkin's featured videos" />
        <Row xs={1} xxl={3}>
          <Col>
            <YoutubeEmbed embedId="QL9Wiz7p5kI" />
          </Col>
          <Col>
            <YoutubeEmbed embedId="wZkpd0O685U" />
          </Col>
          <Col>
            <YoutubeEmbed embedId="6ZRH_yhvDfg" />
          </Col>
        </Row>
        <SectionHeader title="Grimro's featured videos" />
        <Row xs={1} xxl={3}>
          <Col>
            <YoutubeEmbed embedId="YhrNcUfNvfU" />
          </Col>
          <Col>
            <YoutubeEmbed embedId="IE8GM65W2E8" />
          </Col>
          <Col>
            <YoutubeEmbed embedId="ArppDH5_Zc4" />
          </Col>
        </Row>
        <SectionHeader title="TimaeuSS featured videos" />
        <Row xs={1} xxl={3}>
          <Col>
            <YoutubeEmbed embedId="B3YJqrTX3VQ" />
          </Col>
          <Col>
            <YoutubeEmbed embedId="HaOZVLvH54Q" />
          </Col>
          <Col>
            <YoutubeEmbed embedId="PWgg6ZpuGMY" />
          </Col>
        </Row>
        <SectionHeader title="MrPokke's featured videos" />
        <Row xs={1} xxl={3}>
          <Col>
            <YoutubeEmbed embedId="CyhHOxWH_hg" />
          </Col>
          <Col>
            <YoutubeEmbed embedId="Y0grl1GGgEw" />
          </Col>
          <Col>
            <YoutubeEmbed embedId="hs55OvHQYfE" />
          </Col>
        </Row>
      </div>
    </DashboardLayout>
  );
};

export default HSRWatchLivePage;

export const Head: React.FC = () => (
  <Seo
    title="Watch our partners | Honkai: Star Rail | Prydwen Institute"
    description="Check out our Honkai: Star Rail Content Creator partners!"
  />
);
